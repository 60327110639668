<template>
  <div class="about" >
    <div class="d-flex flex-column min-vh-100">
    <a-spin tip="資料載入中..." :spinning="spinning">
    <Menubar msg="test" @query="query" @getwinsize="getwinsize" v-if="type=='N'" ref="MyBar" />  
      <div class="lineHeader" v-if="type=='Y'">
        <a-button type="link" @click="toNext()"  class="fontstyle">
          <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="chevron-left" variant="light" />
          回上一頁
        </a-button>      
      </div>
      <div style="padding:1.7rem 0 0 0;" v-if="type=='Y'" />        
      <ul>
        <li>
          <div class="mt-3" >
            <div v-if="type=='N'" style="padding:0.002rem;background-color: #f3f5f7;border-top-left-radius: 10px;border-top-right-radius: 10px;">
            <a-button type="link" @click="toNext()" class="fontstyle2" >
              <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="chevron-left" variant="primary" />
               回上一頁 
            </a-button>
            </div>              
            <Regapply v-if="JSON.stringify(this.objs)!=='{}'" :kinds="type" :obj="objs" @reload="reload()" />
          </div>
        </li>
      </ul>          
    </a-spin>  
    <div class="wrapper flex-grow-1"></div>      
    <Footbar msg="test" v-if="type=='N'" />
    <div class="applyfooter" v-if="windowsize.width<=991">
    </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import { macService } from '../_services'
import { checktokens } from "../api/utils";
import * as moment from "moment/moment";
import Regapply from './panel/Regapply.vue'
import Menubar from '@/components/Menubar.vue'
import Footbar from '@/components/Footbar.vue'
import router from '../router'

const queryData = params => {
  return macService.macfind(params.id) 
};

const queryData3 = params => {
  return macService.macfindall(params.id,params.uid) 
};

export default {
  name: 'Regjoin',
  components: {
    Menubar,
    Footbar,
    Regapply,
  }, 
  props: {
    msg: String
  }, 
  data() {
    return {
      windowsize: {
            width: 0,
            height: 0
        },  
      spinning: true,      
      classtheme:null,
      objs: {}, 
      type:'N',     
      uId:'',
    };
  },  
  mounted(){
        //console.log(this.$route.query.userId);
        this.reload()
        this.$refs["MyBar"].closebar(false);
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },  
  methods: {  
    query(rs){
      this.classtheme = rs
    },
    getwinsize(val){
      this.windowsize.width = val.width
      this.windowsize.height = val.height
    },         
    fetch(params = {}) {
      queryData({
        ...params,
      }).then(({ data }) => {
          this.objs = data;
      });
    },
    fetch3(params = {}) {
      queryData3({
        ...params,
      }).then(({ data }) => {
          this.objs = data;
          this.spinning = false;
      });
    },    
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    check(){
        if(this.user!==null){
            let verify= checktokens(String(this.status.token))                  
            if(verify===null){ 
               router.push({ name: 'Actinfo', params: { id: this.uId }})           
            }
        }else{
            router.push({ name: 'Actinfo', params: { id: this.uId }})           
        }           
    },

    async reload(){
      this.type = this.$route.params.type        
      this.uId = this.$route.params.id
      this.check()
      let userid ='null'
      if(this.user!==null){
        userid=this.user.data.user.id
      }
      this.fetch3({id:this.uId,uid:userid});
    },
    
    toNext(){
      router.go(-1)
    },     

  },  
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 0px;
   background-color: #f0f8f8;
   color: #f0f8f8;
   text-align: center;
   
}
.lineHeader {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 7;
   opacity:1;
   width: 100%;
   height: 2.3rem;
   padding: 0.2rem;
   background-color: #3b1b75;
   color: #f0f8f8;
   text-align: center;
   
}
.fontstyle{
   font-size:0.8rem;
   color:#faf8f8;
}

.fontstyle2{
   font-size:0.8rem;
   color:#447dc7;
}


</style>

