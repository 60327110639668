<template>
  <div>
        <b-alert
          v-model="showTop"
          class="position-fixed fixed-top m-0 rounded-0"
          style="z-index: 2000;"
          :variant="mstype"
          dismissible
        >
         <b-spinner small></b-spinner><span v-text="msg"></span> 
        </b-alert>     
    <div v-if="JSON.stringify(obj)!=='{}'" >
    <!-- 
      提交事件不再重载页面 
      prevent 修飾符會叫用 event.preventDefault() 以阻止表單送出，可以把例子中的 @submit.prevent 拔掉，比較看看按下 submit 按鈕的差異。
      在沒有 @submit.prevent 的情況下，頁面會閃一下，這時頁面已送出表單並重新載入了，而加上 @submit.prevent 後畫面不會重新載入。
    -->
    <b-form ref="myForm"  v-on:submit.prevent="applyForm"> 
      <b-card
        header="活動報名表"
        :img-src="obj.acts_mact!==''? obj.acts_mact.url:catImg('event_1080x540.png')"
        header-border-variant="light"
        header-bg-variant="light"
        align="center"
        class="card-3"
      >
        <b-card-body style="max-width:980px;padding:15px 0px 5px 0px;" class="overflow-auto">
          <b-card-text>
            <h4><strong><span v-text="obj.acts_mact.actitle"></span></strong></h4>
            <div class="text-left" style="padding:5px 5px 5px 25px;">              
              <div class="classempy">
                <b-icon icon="clock" variant="success"></b-icon> 活動時間 : <br/>
                <span style="font-size: 14px;" v-text="obj.acts_mact.sdate +' '+  obj.acts_mact.stime.substring(0, 5) +' ~ ' + (obj.acts_mact.edate==obj.acts_mact.sdate?'':obj.acts_mact.edate) +' '+  obj.acts_mact.etime.substring(0, 5)"></span>
              </div>
              <div class="classempy">
                <b-icon icon="geo-alt" variant="success"></b-icon> 活動地點 : <br/>
                <span style="font-size: 14px;" v-text="obj.acts_mact.address"></span>
              </div> 
              <div class="classempy" style="margin-top:0.5rem;margin-bottom:0.5rem;" v-if="obj.acts_mact.checked">
                <b-icon icon="person-lines-fill" variant="success"></b-icon> 報名人數限制 : <br/>
                &emsp;<span v-text="'限制報名人數 : '+obj.acts_mact.value"></span>
              </div>  
              <a-checkbox v-if="obj.acts_mact.checked==false" @change="onChange">
                  全選/取消
              </a-checkbox>

            <div class="ccle"> 
              
              <div style=" border: 1px solid #d9d9d9; border-radius: 10px;margin-top:0.2em;" >
                  <a-calendar @select="onSelect" :defaultValue="currentTime" :locale="locale" :disabledDate="disabledDate" :fullscreen="true">
                      <div slot="dateCellRender" slot-scope="value" class="events" style="overflow-x: hidden;overflow-y:hidden;">
                          <div v-for="item  in getListData(value)" :key="item.id"  >
                            <div class="notes" :style="{'background-image': item.type==='Y'?'linear-gradient(to bottom, #c5ddf8, #3386e7)':'linear-gradient(to bottom, #dbdedb, #aeb1ae)','cursor':'pointer'}">   
                              <b-icon :icon=" item.type==='Y'? 'check2-circle':'bookmark-x'" style="margin-top:1.8rem;color:aliceblue;" font-scale="2" ></b-icon>                           
                            </div>
                          </div>
                      </div>
                      <template slot="monthCellRender" slot-scope="value">
                          <div v-if="getMonthData(value)" class="notes-month">
                          <section>{{ getMonthData(value) }}</section>
                          <span>Backlog number</span>
                          </div>
                      </template>
                  </a-calendar> 
              </div>  
              <div v-if="selectitem.length>0" style="max-width: 600px;border-color:#d9d9d9;border-style: solid;border-width: 0.05em;border-radius:  15px;">                    
                <ul style="list-style: none;margin-left: -1em;" class="rsgrid">
                    <li  v-for="item in selectitem" :key="item.id">
                      <div id="rcorners3">
                        <span  v-text="getdate(item.sdate)+' '" />
                        <span @click="removes(item.id)" v-text="'(刪除)'" style="cursor: pointer;" />
                      </div>                     
                    </li>
                </ul>               
              </div>
              <div v-if="selectitem.length==0 && others.length>0" style="max-width: 600px;">                    
                <ul style="list-style: none;margin-left: -1em;" class="rsgrid">
                    <li  v-for="item in others" :key="item.id">
                      <div id="rcorners4">
                        <span  v-text="getdate(item.sdate)+' 該課程已額滿' " />
                      </div>                     
                    </li>
                </ul>               
              </div>
            </div>
              <hr class="hrs" > 
                <div v-if="list1.length>0 && obj.acts_joinform.fid==0">
                  <div v-if="obj.acts_mact.fee">                    
                      <div class="form-group" id="fees"> 
                         <label for="idexample">活動參加費用 <span style="color:red">*</span></label>                         
                              <a-row type="flex" >
                                  <a-col :span="7" :offset="1" v-for="items in obj.acts_mact.items" :key="items.id">
                                          <div >       
                                              <span> 
                                                  <input v-model="selecteds" name="customRadioInline1" type="radio" :value="items.id" required/>                                                  
                                                  費用: {{items.fee}} TWD / {{ items.title }} 
                                              </span>
                                          </div>                            
                                  </a-col>                  
                              </a-row>                                
                      </div>
                  </div>                                     
                  <div class="list-group-item" v-for="element in list1" :key="element.id"> 
                      <div class="form-group" v-if="element.input=='text' || element.input=='email' || element.input=='tel' || element.input=='date' || element.input=='file' ">
                          <label for="idexample">{{ element.name }}</label> 
                          <input v-model="element.val"  :type="element.input" class="form-control" id="idexample" autocomplete="off" :required="element.required" aria-describedby="emailHelp" :placeholder="element.placeholder">
                      </div> 
                      <div class="form-group" v-if="element.input=='radio' || element.input=='checkbox'">
                          <label for="idexample">{{ element.name }}</label>
                          <br />
                              <a-row type="flex" >
                                  <a-col :span="5" :offset="1" v-for="obj in element.list" :key="obj.value">
                                          <div >       
                                              <span> 
                                                  <input v-if="element.input=='radio'" v-model="element.val" name="customRadioInline1" :type="element.input" :value="obj.value"/>
                                                  <input v-if="element.input=='checkbox'" v-model="element.ary[obj.value]" :name="element.id" :type="element.input" :value="obj.value"/> 
                                              : {{ obj.text }} 
                                              </span>
                                          </div>                            
                                  </a-col>                  
                              </a-row>                                
                      </div> 
                      <div class="form-group" v-if="element.input=='select'">
                          <label for="idexample">{{ element.name }}</label>
                          <br />
                          <select v-model="element.val" class="custom-select">
                              <option selected>--請選擇---</option>
                              <option v-for="obj in element.list" :key="obj.value" :value="obj.value">{{obj.text}}</option>
                          </select>   
                      </div>
                  </div>                                                        
                </div>              
            </div>
          </b-card-text>
        </b-card-body>
           <template #footer>
                 <b-button type="submit"  squared variant="primary" :disabled="disables"  block>
                   <b-spinner v-if="disables" small type="grow"></b-spinner>
                   立即報名
                   <b-icon icon="arrow-up-circle-fill" aria-hidden="true"></b-icon>
                 </b-button>
           </template>
      </b-card>
      </b-form>
    </div>
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex';
import { joinService } from '../../_services'
//import { checktokens } from "../../api/utils";
import 'moment/locale/zh-tw';
import * as moment from "moment/moment";
import Formdata from '../../assets/json/formdata.json'
import router from '../../router'

const postdata = obj => {
  return  joinService.postform(obj)
}
const postpay = obj =>(joinService.postpay(obj))


export default {
  name: "Regapply",
  props: {
    obj: Object, 
    kinds : String,
  },
  data() {
    return {     
      xurl: "",
      disables:false,
      showTop: false, 
      tpay:false, //20220922 中斷付費功能
      currentTime:moment(),
      msg:"資料正在送出....",
      mstype:"",
      selectitem:[],
      myJson: [], 
      arryitems:[], 
      information:[],          
      list1: [],
      others:[],
      selecteds:null,   
      iconLoading: false,   
      imgs: {
        width: 0,
        height: 0
      },
      locale:{
        lang:{
          month:'月',
          year:'年',
        }
      },      

    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  methods: {
    ...mapActions("account", ["checktoken", "logout"]),
    
    async applyForm() {

      if(this.selectitem.length==0){  
        this.$message.error('您尚未選擇活動課程，謝謝');      
        /*if(this.obj.acts_mact.checked){
          this.$message.error('已達報名人數限制，無法接受報名，謝謝');
        }else{
          this.$message.error('報名時間已逾期，不接受報名． 謝謝');
        }*/
        return false
      }
      let items =[]    
      this.showTop = true;
      this.disables=true
      this.mstype="primary"
      let usernames=''
      this.others =[]
      if(this.obj.acts_joinform.fid==0){
        for(var obj in this.list1){
          
          if(this.list1[obj].id==1){
            usernames= this.list1[obj].val
          }
          if(this.list1[obj].input==='checkbox')
          {     
            let tmp = Object.entries(this.list1[obj].ary)
            for(var rs in tmp){
              if(tmp[rs][1]==true){                          
                items.push({
                  oid: this.list1[obj].id,
                  items:tmp[rs][0].toString()
                }) 
              }
            }       
          }else{
            items.push({
                  oid: this.list1[obj].id,
                  items:this.list1[obj].val.toString()
                }) 
          }
        }
      }else{
        items=this.obj.acts_joinform.myrsoptions
      }
      let check = 'Y'
      if(this.obj.acts_mact.fee && this.selecteds!==""){
        check='N'
      }
      let tmps=[];
      for(var i in this.selectitem){
          tmps.push({
            id : '',
            uid :  this.user.data.user.id,     //申請者
            aid :  this.selectitem[i].id,    //課程檔 id
            sdate : this.selectitem[i].sdate ,   // 舉辦日期
            edate : this.selectitem[i].edate ,   // 結束日期
            states: 'N' ,   // 狀態
            roles : 'actor',    // 活動角色
            score : this.obj.acts_mact.actor,   //參加分數
            byrsoptions : items //報名表            
          })
      } 

      let playload ={
        id:this.obj.acts_joinform.fid==0?0:this.obj.acts_joinform.id,
        fid:this.obj.acts_mact.id,
        uid:this.user.data.user.id,
        Sdate:this.obj.acts_mact.sdate +" "+this.obj.acts_mact.stime,
        Edate:this.obj.acts_mact.edate +" "+this.obj.acts_mact.etime,
        roles:'actor',
        states:check,
        myrsoptions:items,
        acts_detrimal:tmps,
        wchecked:this.obj.acts_mact.Checked,
        value:this.obj.acts_mact.value
      }
      //console.log("playload:",playload)
      postdata(playload).then(({data,other}) =>{
              //this.$message.success('新增資料成功',2);
              //if(data.id!==undefined){ 
              console.log(data,other);
              if(data!==undefined && data !==null){
                this.msg = "報名資料已送出, 謝謝您的報名!"
                this.mstype="success"
                setTimeout(() => {   
                    this.showTop = false;
                    this.disables = false;
                    this.inidata()
                    if(this.obj.acts_mact.fee && this.selecteds!=="" && this.tpay){
                      let rsitem = this.obj.acts_mact.items.filter((el)=>{return el.id ===this.selecteds})
                      let tmp={
                          fid:data.id,mid:this.obj.acts_mact.id,
                          uid:this.user.data.user.id,
                          kinds: this.kinds,
                          orderusername:usernames,
                          ordertitle:this.obj.acts_mact.actitle,
                          orderids:this.selecteds,                          
                          orderitem:rsitem[0].title,
                          orderdesc:rsitem[0].desc,
                          orderamount: rsitem[0].fee.toString()                         
                      }
                      postpay(tmp).then((rsponse)=>{
                        tmp = this.decodeHtml(rsponse)
                        var htmlObject = document.createElement('div');
                        htmlObject.innerHTML = tmp;  
                        document.write(htmlObject.innerHTML);                        

                      })                     
                    }else{
                      this.selectitem=[]
                      this.$emit('reload')
                      if(other!==null){
                        this.others = other
                      }else{
                        if(this.kinds=='N'){
                          router.replace('/').catch(err=>{err})
                        }else{
                          router.replace({ name: 'Myline'}).catch(err=>{err})
                        }
                      }
                    }

                    clearInterval();
                }, 3000);  
            }else{
              setTimeout(() => {
                this.showTop = false;
                this.disables = false;
                this.selectitem=[]
                this.$emit('reload')
                if(other!==null){
                  this.others = other
                }
                }, 3000);		
            }                      
      });      


    }, 
    
    onChange(e){
      if(e.target.checked==true){
        let item = this.obj.acts_mact.acts_actclass
        for (let i = 0; i < item.length; i++) {
          if(item[i].checked && (item[i].totail==item[i].value)){
            continue;
          }
          if(this.getformat(item[i].edate) > this.getformat(this.currentTime)){
            if(this.obj.acts_joinform.acts_detrimal!==null){
              let tmp = this.obj.acts_joinform.acts_detrimal.filter(rs=>rs.aid==item[i].id)
              if(tmp.length==0 && this.oncheck(item[i].id).length==0){
                this.selectitem.push(item[i]);
              }
            }else{
              if(this.oncheck(item[i].id).length==0){
                this.selectitem.push(item[i]);
              }
            }
          }
        }
      }else{
        this.selectitem =[]
      }

    },

    getListData(value) {
      let listData=[];
      if(this.obj.acts_mact.acts_actclass!==null){ 
      this.obj.acts_mact.acts_actclass.forEach((item)=>{          
          if(moment(item.sdate).format('YYYY-MM-DD') == value.format("YYYY-MM-DD")){
              let state = moment(item.edate).format('YYYY-MM-DD HH:mm') >=this.currentTime.format("YYYY-MM-DD HH:mm")?'Y':'N'              
              if(state=='Y' && this.obj.acts_joinform.acts_detrimal!==null){
                state = this.obj.acts_joinform.acts_detrimal.filter(rs=>rs.aid==item.id).length>0?'N':'Y'
              }
              if(this.obj.acts_mact.checked){
                  let tmp = this.obj.acts_mact.acts_actclass.filter(rs=>rs.id==item.id)
                  if(tmp.length>0){
                    if(tmp[0].totail==tmp[0].value){
                      state ='N'
                    }
                  } 
                }                  
              listData.push(
                  {
                    type:  state,
                    id:item.id,
                    fid:item.fid,          
                    orgid:item.orgid,       
                    url:item.url,           
                    actitle:item.actitle,          
                    sdate:item.sdate,         
                    edate:item.edate,                     
                    state:item.status                                                                                           
                }
              )
          }            
          });
          return listData || [];
      }
      return listData || [];

    }, 
    
    disabledDate(value){
      if(this.obj.acts_mact.acts_actclass!==null){ 
        let state = ''
      this.obj.acts_mact.acts_actclass.forEach((item)=>{ 
          if(moment(item.sdate).format('YYYY-MM-DD') == value.format("YYYY-MM-DD")){
              state = moment(item.edate).format('YYYY-MM-DD HH:mm') >=this.currentTime.format("YYYY-MM-DD HH:mm")?'Y':'N'              
              if(state=='Y' && this.obj.acts_joinform.acts_detrimal!==null){
                state = this.obj.acts_joinform.acts_detrimal.filter(rs=>rs.aid==item.id).length>0?'N':'Y'
              }
              if(this.obj.acts_mact==undefined){
                return
              }
              if(this.obj.acts_mact.checked){
                  let tmp = this.obj.acts_mact.acts_actclass.filter(rs=>rs.id==item.id)
                  if(tmp.length>0){
                    if(tmp[0].totail==tmp[0].value){
                      state ='N'
                    }
                  } 
              } 
              return 
          }       
          });
          return state=='N'? true:false   
      }
    },     
    getformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },      
    
   async onSelect(e){
      let tmp = this.getdate(e._d);
      this.others=[]
        let obj = this.obj.acts_mact.acts_actclass.filter(rs=>moment(rs.sdate).format("YYYY-MM-DD")==tmp)
        if(obj.length>0){
            if(obj[0].checked && (obj[0].totail==obj[0].value)){
            return false
          }
        }
        if(this.obj.acts_mact.checked){          
          let results = await this.checkJoin(obj[0].id)
          if(obj[0].totail <= results.data){
            this.$message.error('該課程報名額滿 :' + results.data);    
            return false
          }
        }       

        if(obj.length>0 && this.getformat(obj[0].edate) > this.getformat(this.currentTime)){          
          if(this.obj.acts_joinform.acts_detrimal!==null){
            let tmp = this.obj.acts_joinform.acts_detrimal.filter(rs=>rs.aid==obj[0].id)
            if(tmp.length==0 && this.oncheck(obj[0].id).length==0){
              this.selectitem.push(obj[0]);
            }
          }else{
            if(this.oncheck(obj[0].id).length==0){
              this.selectitem.push(obj[0]);
            }
          }
        }        
    },

    oncheck(val){
      return this.selectitem.filter(rs=>rs.id==val)
    },
    
    getdate(val){
        return moment(val).format("YYYY-MM-DD"); 
    },    
    
    decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    },      

    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },

    removes(val) {
      this.selectitem = this.selectitem.filter(rs=>rs.id!=val)
    },
      

    async inidata(){  
        this.list1=[]            
        if(JSON.stringify(this.obj.acts_mact)!=='{}'){ 
            this.list1 =[]
            for(var i in this.obj.acts_mact.options){
                let tmp= await this.myJson.filter((el)=>{return el.id ===this.obj.acts_mact.options[i].oid})
                let rs  = tmp[0]
                if(rs.id==1){
                  rs.val = this.user.data.user.username
                }else if(rs.id==2){
                  rs.val = this.user.data.user.hphone!==undefined?this.user.data.user.hphone:'' 
                }else if(rs.id==3){
                  rs.val = this.user.data.user.email
                }else if(rs.id==8 || rs.id==19){
                  rs.ary = {} 
                }else if( rs.id==18 || rs.id==17 || rs.id==16 || rs.id==15 || rs.id==14 || rs.id==13 || rs.id==12 || rs.id==11 || rs.id==10 || rs.id==9 || rs.id==7 || rs.id==6 || rs.id==5 || rs.id==4){
                  rs.val = ""
                }
                this.list1.push(tmp[0])
            }           
        }           
    },

    checkJoin(id) {
      return new Promise((resolve) => {
        resolve(joinService.queryJoin(id.toString()));
      });
    }
  

  },
  mounted () {
    this.inidata()
    //console.log("mounted:",this.list1)
  },
  created() {    
    this.myJson = Formdata  
    //console.log("created:",this.list1)
  },
  beforeDestroy() {}
};
</script>
<style scoped>
.card-body {
  padding: 5px 0px 5px 0px;
}
.ddiv {
  padding: 7px;
  border-radius: 17px;
  margin: 5px auto;
  box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
  transition: all 0.3s ease;
}
.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.bimg {
  max-height: 100%;
  max-width: 100%;
  opacity: 0.7;
  width: auto;
  height: auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.classempy{
  padding:0;
}

.bton {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: 40px;
  position: absolute;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  bottom: 1750px;
  left: 26%;
  right: 0;
}

.btn {
    background: linear-gradient(90deg, #65b5f7 0%, #4ea0e4 35%, #418fd0 100%);
    margin-left:-0.01rem;
    width:100%; 
    height:60px; 
    float:left; 
    color: #ecf2f7;
    font-weight:bold;
    display:inline;
    border-width:1px;
    border-color: #65b5f7;
    border-left-style:dotted;
    text-align:center; 
    line-height:60px;
}

.btn:hover {
    background: linear-gradient(90deg, #7dc3fd 0%, #62b5f8 35%, #418fd0 100%);
    cursor: pointer;
}
.card {
}
.card-body{
}
.card-img{
  max-width: 980px;  
}
.card-header{
    font-size: 18px;
    font-weight:bold;
    color: #b7bdc4;
}
.card-footer {
  padding:1px;
}

.hrs {
    border: 0;
    padding-top: 1px;
    background: linear-gradient(to right, transparent, #b0b0b4, transparent);
}

#fees{
  background-color:#fef1f1;
  padding: 15px;
  border-style:solid;
  border-width:1.5px;
  border-color:#dadada;
}

.notes{
  position: absolute;
  border-radius: 15px;
  top: 25%;
  left: 0;
  width: 80%;
  height: 70%;
  text-align: center; /* 文本水平居中 */
  vertical-align: middle; /* 文本垂直居中 */  
  cursor:pointer;
  }

.ccle {
	margin-left: -1rem;
}
.ccle div {
	display: inline-block;
	vertical-align: top;
	margin: 1px;
  line-height: 1.5em;
}

.rsgrid li { 
  display: inline-block;
	vertical-align: top;
  line-height: 1.5em;
}

#rcorners3 {
  border-radius:  15px 15px;
  font-size:0.9em;
  text-align: center;
  color:#f7f5f0;
  background: #639ea8;
  padding: 0.2em; 
  width: 135px;
  height: 25px; 
  margin: 0.2em;
} 
#rcorners4 {
  border-radius:  8px 8px;
  font-size:0.9em;
  text-align: center;
  color:#f7f5f0;
  background: #af6941;
  padding: 0.3em; 
  max-width: 245px;
  height: 25px; 
  margin: 0.2em;
}   
</style>

<style>
.ant-fullcalendar-header{
  padding:10px;
  margin-top:-10px;
}
.ant-fullcalendar-header .ant-radio-group {
    display: none !important;
}
.ant-fullcalendar-year-select{
    padding:5px;
}
.ant-fullcalendar-month-select{
      left: -3px;
}
.ant-fullcalendar{
  font-size:12px;
  font-weight: bold;
}
</style>




